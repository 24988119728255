export const ROUTES = {
  OVERVIEW: "OVERVIEW",
  APPLICATIONS: "APPLICATIONS",
  APPLICATION_INSTALLED: "APPLICATION_INSTALLED",
  APPLICATION_AVAILABLE: "APPLICATION_AVAILABLE",
  TRASH: "TRASH",
  TRASH_DETAIL: "TRASH_DETAIL",
  TRASH_NO_DATA: "TRASH_NO_DATA",
  NOT_FOUND: "NOT_FOUND",
  NOT_LOGGED_IN: "NOT_LOGGED_IN",
  SETTINGS: "SETTINGS",
  LOGS: "LOGS",
}
