import { render, staticRenderFns } from "./AppStoreItem.vue?vue&type=template&id=01d17e48&scoped=true&"
import script from "./AppStoreItem.vue?vue&type=script&lang=js&"
export * from "./AppStoreItem.vue?vue&type=script&lang=js&"
import style0 from "./AppStoreItem.vue?vue&type=style&index=0&id=01d17e48&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.7.3_prettier@2.8.4_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d17e48",
  null
  
)

export default component.exports