<template>
  <div class="pt-8">
    <v-row>
      <v-col>
        <span>
          {{ $t("trashPage.clickOnTheRow") }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "TrashNoData",
}
</script>

<style scoped></style>
