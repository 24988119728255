<template>
  <v-progress-linear color="primary" indeterminate rounded height="6" />
</template>

<script>
export default {
  name: "BaseProgressBarLinear",
}
</script>

<style scoped></style>
