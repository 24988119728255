var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between mb-2 ml-4"},[_c('label',{staticClass:"d-flex align-center mr-4",on:{"click":_vm.onSelectAll}},[_c('v-simple-checkbox',{attrs:{"indeterminate":!_vm.areAllSelected && _vm.isSomethingSelected,"value":_vm.isSomethingSelected},on:{"input":_vm.onSelectAll}}),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("trashPage.selectAll")))])],1),_c('div',{staticClass:"d-flex align-center text--secondary"},[(_vm.$slots.actions)?_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t("trashPage.selectionActions"))+": ")]):_vm._e(),_vm._t("actions")],2)]),_c('v-card',{staticClass:"custom-table",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"footer-props":{
        'items-per-page-options': [5, 10, 20],
      },"show-select":"","item-key":"id","loading":_vm.isLoading,"loading-text":_vm.$t('grid.state.loading'),"server-items-length":_vm.total,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`header.data-table-select`,fn:function(){return undefined},proxy:true},_vm._l((_vm.headers),function(item){return {key:`header.${item.value}`,fn:function({ header }){return [_c('span',{key:item.value,staticClass:"text-capitalize font-weight-bold"},[_vm._v(" "+_vm._s(header.text)+" ")])]}}}),{key:"item",fn:function(props){return [_c('tr',{key:props.index,staticClass:"applinth-pointer",class:_vm.activeIndex === props.index && _vm.activeIndexId === props.item.id
              ? 'selected-row'
              : ''},[_c('td',[_c('v-checkbox',{staticClass:"ma-auto pt-0 z-10",attrs:{"primary":"","hide-details":"","dense":""},on:{"change":(value) => {
                  props.select(value)
                }},model:{value:(props.isSelected),callback:function ($$v) {_vm.$set(props, "isSelected", $$v)},expression:"props.isSelected"}})],1),_vm._t("default",null,{"items":props,"expanded":props.isExpanded})],2)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }