import { render, staticRenderFns } from "./TrashUpdateModal.vue?vue&type=template&id=4788fb78&scoped=true&"
import script from "./TrashUpdateModal.vue?vue&type=script&lang=js&"
export * from "./TrashUpdateModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.7.3_prettier@2.8.4_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4788fb78",
  null
  
)

export default component.exports