<template>
  <h2 class="title font-weight-bold"><slot /></h2>
</template>

<script>
export default {
  name: "SubHeading",
}
</script>

<style scoped></style>
