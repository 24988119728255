import auth from "./endpoints/auth"
import overview from "./endpoints/overview"
import appStore from "./endpoints/appStore"
import trash from "./endpoints/trash"
import settings from "./endpoints/settings"
import logs from "./endpoints/logs"
import authorize from "@/api/endpoints/authorize"

export const API = {
  auth,
  overview,
  appStore,
  trash,
  settings,
  authorize,
  logs,
}
