var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"height":"40","show-arrows":true},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.settingsConfig),function(form){return _c('v-tab',{key:form.key,staticClass:"text-transform-none body-2 font-weight-medium primary--text"},[_vm._v(" "+_vm._s(form.publicName)+" ")])}),1)],1)],1),_c('v-tabs-items',{staticClass:"mt-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.settingsConfig),function(form,index){return _c('v-tab-item',{key:form.key,class:form.key === 'info'
          ? 'application-settings-wrapper-info'
          : 'application-settings-wrapper-form'},[(form.key === 'info')?[_c('div',{staticClass:"mt-2 ml-2",domProps:{"innerHTML":_vm._s(form.info)}})]:[(form.description.length > 0)?_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(form.description)+" ")])],1):_vm._e(),_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',[_c('validation-observer',{ref:form.key,refInFor:true,attrs:{"tag":"form","slim":""},on:{"submit":function($event){$event.preventDefault();return (() => _vm.saveForm(form.key, form.publicName)).apply(null, arguments)}}},_vm._l((form.fields),function(field){return _c('div',{key:field.key},[(field.description)?_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(field.description)+" ")]):_vm._e(),(field.type === 'text' || field.type === 'url')?_c('validation-provider',{attrs:{"slim":"","name":field.key,"rules":{
                    required: field.required,
                    url: field.type === 'url',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"readonly":field.readOnly,"disabled":field.disabled,"label":field.label,"error-messages":errors,"input-type":field.type},model:{value:(_vm.settingsForms[index].fields[field.key]),callback:function ($$v) {_vm.$set(_vm.settingsForms[index].fields, field.key, $$v)},expression:"settingsForms[index].fields[field.key]"}})]}}],null,true)}):_vm._e(),(field.type === 'selectbox')?_c('validation-provider',{attrs:{"name":field.key,"slim":""}},[_c('base-select',{attrs:{"clearable":!field.readOnly,"readonly":field.readOnly,"disabled":field.disabled,"label":field.label,"items":_vm.getEntries(field.choices)},model:{value:(_vm.settingsForms[index].fields[field.key]),callback:function ($$v) {_vm.$set(_vm.settingsForms[index].fields, field.key, $$v)},expression:"settingsForms[index].fields[field.key]"}})],1):_vm._e(),(field.type === 'multiselect')?_c('validation-provider',{attrs:{"name":field.key,"slim":""}},[_c('base-select',{attrs:{"clearable":!field.readOnly,"readonly":field.readOnly,"disabled":field.disabled,"label":field.label,"items":_vm.getEntries(field.choices),"multiple":true},model:{value:(_vm.settingsForms[index].fields[field.key]),callback:function ($$v) {_vm.$set(_vm.settingsForms[index].fields, field.key, $$v)},expression:"settingsForms[index].fields[field.key]"}})],1):_vm._e(),(field.type === 'checkbox')?_c('validation-provider',{attrs:{"name":field.key,"slim":""}},[_c('base-checkbox',{staticClass:"ml-3",attrs:{"readonly":field.readOnly,"disabled":field.disabled,"label":field.label},model:{value:(_vm.settingsForms[index].fields[field.key]),callback:function ($$v) {_vm.$set(_vm.settingsForms[index].fields, field.key, $$v)},expression:"settingsForms[index].fields[field.key]"}})],1):_vm._e(),(field.type === 'number')?_c('validation-provider',{attrs:{"slim":"","name":field.key,"rules":{
                    required: field.required,
                    numeric: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"readonly":field.readOnly,"disabled":field.disabled,"label":field.label,"error-messages":errors},model:{value:(_vm.settingsForms[index].fields[field.key]),callback:function ($$v) {_vm.$set(_vm.settingsForms[index].fields, field.key, $$v)},expression:"settingsForms[index].fields[field.key]"}})]}}],null,true)}):_vm._e(),(field.type === 'password' && !form.readOnly)?_c('app-item-password-modal',{attrs:{"form-key":form.key,"field-key":field.key,"app-key":_vm.app.key,"input":field,"disabled":_vm.isRequestPending,"label":field.label,"name":field.name,"button-class":"mb-3"}}):_vm._e()],1)}),0)],1)],1),(!form.readOnly)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('actions-wrapper',[_c('base-button',{attrs:{"type":"submit","color":"primary","button-title":_vm.$t('button.save'),"on-click":() => _vm.saveForm(form.key, form.publicName),"disabled":_vm.isRequestPending,"loading":_vm.isSaving}})],1)],1)],1):_vm._e()]],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }